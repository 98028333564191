function Footer() {
  return (
    <footer>
      <p
        style={{
          fontSize: "2vh",
          fontFamily: "Inter, sans-serif",
          margin: "0vh",
        }}
      >
        © 2022 IDAN BARDUGO. ALL RIGHTS RESERVED.
      </p>
    </footer>
  )
}

export default Footer
