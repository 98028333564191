export const cards = [
  {
    id: 1,
    title: "Front-End Development",
    capabilities: [
      "Building user interfaces from scratch.",
      "Using a state management solutions: Redux and MobX.",
      "Experience in building complex UI systems with huge scale of data.",
    ],
    tools: [
      "TypeScript",
      "React",
      "React Hooks",
      "React Router v5",
      "Redux",
      "MobX",
      "Material UI",
      "Cypress",
    ],
  },
  {
    id: 2,
    title: "Back-End Development",
    capabilities: [
      "Building a scaleable server-side from scratch. (Router, Business-Logic, Data Access Layer, Working with different DataTypes).",
      "Applying Security and Authorization to restrict unauthenticated visitors from accessing routes on the server and UI componetns using JWT.",
      "Working with sub-processes in Python.",
    ],
    tools: [
      "TypeScript",
      "Python",
      "Node.js",
      "Express.js",
      "Express Router",
      "PostgreSQL",
      "Prisma, Sequelize",
      "Swagger Codegen",
      "JWT",
    ],
  },
  {
    id: 3,
    title: "Networking",
    capabilities: [
      "Able to isolate a networking problem to specific area and solve it.",
      "PCAP and the understanding of where to take it from.",
      "Logs reading, analyzing.",
    ],
    tools: [
      "Python",
      "Bash",
      "Regex",
      "VPN",
      "Wireshark",
      "Firewall Management",
      "Docker",
      "Troubleshoot networking issues",
      "AWS ( EC2, S3, Cloudfront, Route 53)",
      "CI/CD (Github Actions, Jenkins)",
    ],
  },
  {
    id: 4,
    title: "Machine-Learning",
    capabilities: [
      "Visualization",
      "Pre-Processing - Spliting the data(train-test), Train the data, Scale the data.",
      "Building LSTM model.",
      "Sentiment Analysis.",
    ],
    tools: ["Python", "Numpy", "Keras", "SKLearn", "Textblob"],
  },
]
